/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

export const initMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.getElementById('map'),
				locationElementSelector: '.map_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
						<a class="map_pin" href="${el.getAttribute('link')}" target="${el.getAttribute('target')}">
							<img src="/dist/images/icons/map_pin.svg" alt="View Rates" />
						</a>
						`
					};
				},
			});

			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				if (document.querySelectorAll('.map_source').length < 2) {
					theMap.setZoom(16);
				}

				document.querySelectorAll('.map_source').forEach(i => {
					const markerData = mapHelper.getMarker(i);
					markerData.element.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						i.classList.add('hover');
					});
					markerData.element.addEventListener('mouseleave', () => {
						markerData.element.classList.remove('hover');
						i.classList.remove('hover');
					});
				});
			});
		});
};