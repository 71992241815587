import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const photoCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('photo_caro')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('photo_caro'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true,
					showDots: true,
				};
				let caro = AutomatitCarousel(options);
				document.getElementById('caro_right_chevron').addEventListener('click', () => {
					caro.slideRight();
				});
				document.getElementById('caro_left_chevron').addEventListener('click', () => {
					caro.slideLeft();
				});
			} else {
				let btns = document.getElementById('photo_caro_chevrons');
				btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('photo_caro'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					useChevrons: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();