import { contactSubmit } from './contact.js';
import { mobileToggle } from './header.js';
import { photoCaro } from './photo_gallery.js';
import { initMap } from './map.js';
import { facilityCaro } from './facility.js';

if (document.getElementById('site_mobile_toggle')) {
	mobileToggle();
}

photoCaro.init();

if (document.querySelector('#map')) {
	initMap();
}

facilityCaro.init();

if (document.getElementById('contact_form')) {
	contactSubmit();
}
